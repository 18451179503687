import React from 'react';
import {
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { colors } from '../../themes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMapPin,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Formik, Form, Field } from 'formik';
import styles from './contact.module.scss';
import { Error, Heading } from '../../components';
import { RootState, postContact, store } from '../../redux';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { contactValidationSchema } from '../../helpers';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

interface ContactProps {
	imgSrc?: string | any;
	imagePosition?: 'right' | 'left';
}

export const ContactForm = ({
	prompts,
	currentLocale,
	isSmallScreen,
}) => {
	const initialValues = {
		first_name: '',
		last_name: '',
		email: '',
		message: '',
		mystery: '',
		agreePrivacy: false,
	};

	const translations = prompts[currentLocale];

	const validationSchema = contactValidationSchema(
		prompts[currentLocale],
	);

	const handleSubmit = async (
		values: object,
		{ resetForm },
	) => {
		const { payload: response } = await store.dispatch(
			postContact(values),
		);
		if (!response.error) {
			resetForm();
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({ isSubmitting }) => (
				// consider increasing padding here but it causes issues with height
				<Form
					style={{
						padding: isSmallScreen ? '64px 16px 0' : 16,
						boxSizing: 'border-box',
					}}
				>
					<Typography
						variant="h5"
						component="h2"
						sx={{
							textTransform: 'uppercase',
							color: colors['brp-black'].main,
							marginBottom: '16px',
						}}
					>
						{prompts[currentLocale].contact.hear_from}
					</Typography>
					<Grid
						container
						justifyContent="space-between"
						gap={1}
					>
						<Field
							name="first_name"
							// as={TextField}
							as={TextField}
							label={
								prompts[currentLocale].forms.first_name
							}
							placeholder={
								prompts[currentLocale].placeholder
									.first_name
							}
							// variant="outlined"
							fullWidth
							// margin="normal"
							required
							sx={{ flex: 1 }}
							className={styles.noBorderRadius}
						/>
						<Field
							name="last_name"
							// as={TextField}
							as={TextField}
							label={prompts[currentLocale].forms.last_name}
							placeholder={
								prompts[currentLocale].placeholder.last_name
							}
							fullWidth
							// margin="normal"
							required
							className={styles.noBorderRadius}
							sx={{ flex: 1 }}
						/>
					</Grid>
					<Field
						name="email"
						as={TextField}
						label={prompts[currentLocale].forms.email}
						placeholder={
							prompts[currentLocale].placeholder.email
						}
						variant="outlined"
						fullWidth
						margin="normal"
						type="email"
						required
					/>
					<Field
						name="message"
						as={TextField}
						label={prompts[currentLocale].forms.message}
						placeholder={
							prompts[currentLocale].placeholder.message
						}
						variant="outlined"
						fullWidth
						margin="normal"
						multiline
						rows={4}
						required
					/>
					<Field
						name="mystery"
						style={{ display: 'none' }}
					/>
					<Error name="agreePrivacy" />
					<FormControlLabel
						control={
							<Field
								type="checkbox"
								name="agreePrivacy"
								as={Checkbox}
								color="primary"
								inputProps={{ tabIndex: 6 }}
							/>
						}
						sx={{ marginY: 1, width: '100%' }}
						label={
							<Typography
								variant="body1"
								component="p"
								sx={{ marginBottom: '-3px' }}
							>
								{translations.text.privacy_acknowledged_1}
								<Link
									to="/privacy-policy"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'underline' }}
								>
									{translations.text.privacy_acknowledged_2}
								</Link>
								{translations.text.privacy_acknowledged_3}
							</Typography>
						}
					/>
					<Container
						style={{
							padding: 0,
							justifyContent: 'space-between',
							display: 'flex',
						}}
					>
						<LoadingButton
							type="submit"
							loading={isSubmitting}
							variant="brp-yellow"
							sx={{ mr: 1 }}
						>
							<span>
								{prompts[currentLocale].buttons.send}
							</span>
						</LoadingButton>
						{/* {contactResponse && (
							<p
								style={
									contactResponse.status === 'fulfilled'
										? { color: 'green' }
										: { color: colors['can-am-red'].main }
								}
							>
								{contactResponse.message}
							</p>
						)} */}
					</Container>
				</Form>
			)}
		</Formik>
	);
};

export const Contact: React.FC<ContactProps> = React.memo(
	() => {
		const generateContactSchema = () => ({
			'@context': 'https://schema.org',
			'@type': 'VehicleDealer',
			'@id': 'https://jetpower.hu',
			name: 'Jetpower Hungary',
			image: 'https://jetpower.hu/apple-touch-icon.png',
			address: {
				'@type': 'PostalAddress',
				streetAddress: 'Napsugár u. 5',
				addressLocality: 'Dunaharaszti',
				postalCode: '2330',
				addressCountry: 'HU',
			},
			geo: {
				'@type': 'GeoCoordinates',
				latitude: '47.342069',
				longitude: '19.117197',
			},
			url: 'https://jetpower.hu',
			telephone: ['+36 30 931 0688', '+36 70 775 5073'],
			email: 'info@jetpower.hu',
			hasMap: 'https://maps.app.goo.gl/ksxwYRFjx8cbpJL89',
			openingHoursSpecification: [
				{
					'@type': 'OpeningHoursSpecification',
					dayOfWeek: [
						'Monday',
						'Tuesday',
						'Wednesday',
						'Thursday',
						'Friday',
					],
					opens: '10:00',
					closes: '17:00',
				},
			],
		});
		const currentLocale = useSelector((state: RootState) =>
			state.locale.currentLanguage.toLowerCase(),
		);
		const prompts = useSelector(
			(state: RootState) => state.generic.languagePrompts,
		);
		const theme = useTheme();
		const isSmallScreen = useMediaQuery(
			theme.breakpoints.down('sm'),
		);

		const iconStyle: any = {
			padding: '8px',
			borderRadius: '50%',
			color: colors['brp-yellow'].main,
			flexBasis: '32px',
			fontSize: '20px',
		};
		const textStyle = {
			width: 'max-content',
			color: colors['brp-grey'].main,
			fontSize: '16px',
		};
		const containerStyle = {
			marginBottom: isSmallScreen ? 0 : '16px',
			padding: 0,
			alignItems: 'center',
			display: 'flex',
			// width: 'max-content',
		};
		const imageStyle: any = {
			// position: 'absolute',
			// top: 0,
			width: '75%',
			height: '100%',
			// flexDirection: 'row',
			backgroundColor: colors['brp-black'].main,
			clipPath: isSmallScreen
				? 'none'
				: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%)',
		};

		return (
			<>
				<Helmet>
					<meta
						name="description"
						content="Vegye fel velünk a kapcsolatot! Címünk: Dunaharaszti, Napsugár u. 5. Telefonszámunk: +36 30 931 0688"
					/>
					<script type="application/ld+json">
						{JSON.stringify(generateContactSchema())}
					</script>
				</Helmet>
				<div
					style={{
						position: 'relative',
						backgroundColor: colors['brp-white'].main,
						height: '100%',
					}}
				>
					<Container
						style={{
							paddingLeft: 0,
							paddingRight: 0,
							marginLeft: 0,
							marginRight: 0,
							// height: '100%',
							maxWidth: 'none',
						}}
						// todo: look into how to achieve this with
						// clip path background for left element
						// maxWidth="lg"
					>
						<Grid container style={{ height: '100%' }}>
							<Grid
								item
								xs={12}
								sm={7}
								sx={{
									...imageStyle,
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
									paddingRight: isSmallScreen ? 0 : '20%',
									minHeight: isSmallScreen
										? '30vh'
										: '50vh',
								}}
							>
								<Container
									style={
										isSmallScreen
											? {
													width: '80%',
													marginRight: 'auto',
													marginLeft: 'auto',
													padding: 0,
													// alignItems: 'center',
											  }
											: {
													// width: 'max-content',
													// marginRight: isSmallScreen ? 'auto' : 0,
													// marginLeft: isSmallScreen ? 'auto' : 0,
													// padding: isSmallScreen ? 0 : 'inherit',
													// alignItems: 'center',
											  }
									}
								>
									<Typography
										// variant="h4"
										sx={{
											color: colors['brp-yellow'].main,
											textTransform: 'uppercase',
										}}
										component="p"
									>
										{
											prompts[currentLocale].contact
												.have_a_question
										}
									</Typography>
									<Typography
										variant="h3"
										component="h1"
										sx={{
											textTransform: 'uppercase',
											color: colors['brp-white'].main,
											marginBottom: isSmallScreen
												? 0
												: '16px',
										}}
									>
										{prompts[currentLocale].contact.details}
									</Typography>
									<Container style={containerStyle}>
										<FontAwesomeIcon
											icon={faEnvelope}
											style={iconStyle}
										/>
										<a
											style={textStyle}
											href="mailto:info@jetpower.hu"
											rel="noreferrer"
											target="_blank"
										>
											info@jetpower.hu
										</a>
									</Container>
									<Container style={containerStyle}>
										<FontAwesomeIcon
											icon={faPhone}
											style={{
												...iconStyle,
											}}
										/>
										<a
											href="tel:+36 30 931 0688"
											rel="noreferrer"
											target="_blank"
											style={textStyle}
										>
											+36 30 931 0688
										</a>
									</Container>
									<Container style={containerStyle}>
										<FontAwesomeIcon
											icon={faWhatsapp}
											style={{
												...iconStyle,
											}}
											title="Whatsapp"
										/>
										<a
											href="tel:+36 70 775 5073"
											rel="noreferrer"
											target="_blank"
											style={textStyle}
										>
											+36 70 77 55 073
										</a>
									</Container>
									<Container style={containerStyle}>
										<FontAwesomeIcon
											icon={faMapPin}
											style={iconStyle}
										/>
										{/* <Link style={textStyle} to="/">
										Dunaharaszti
									</Link> */}
										<a
											style={{
												width: 'max-content',
												color: colors['brp-grey'].main,
											}}
											href="https://maps.app.goo.gl/ksxwYRFjx8cbpJL89"
											rel="noreferrer"
											target="_blank"
										>
											Dunaharaszti
										</a>
									</Container>
								</Container>
							</Grid>
							<Grid item sm={5} xs={12}>
								<ContactForm
									prompts={prompts}
									currentLocale={currentLocale}
									isSmallScreen={isSmallScreen}
								/>
							</Grid>
							<Container
								maxWidth="lg"
								sx={{
									marginTop: '64px',
									textAlign: 'center',
									padding: 0,
								}}
							>
								<Heading
									// z-index interferes with colour
									verticalLineColor={
										colors['brp-yellow'].main
									}
									title={
										prompts[currentLocale].contact
											.location_question
									}
									hasCta={false}
									subTitle=""
								/>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.5813612186803!2d19.11354856082153!3d47.34204666822594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741eeca4e7fc0af%3A0xa7a7814526dad8e5!2sJet%20Power%20Hungary!5e0!3m2!1sen!2ssi!4v1694278837608!5m2!1sen!2ssi"
									width="80%"
									height="100%"
									style={{
										height: '50vh',
										marginTop: '64px',
										marginBottom: '64px',
										marginLeft: isSmallScreen ? 0 : 'auto',
										marginRight: 'auto',
										border: 0,
									}}
									allowFullScreen={false}
									loading="lazy"
									title="JetPower Hungary"
									referrerPolicy="no-referrer-when-downgrade"
								></iframe>
							</Container>
						</Grid>
					</Container>
				</div>
			</>
		);
	},
);
