import {
	Button,
	Divider,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {
	RootState,
	getBlogPosts,
	store,
	updateSelectedPost,
} from '../../redux';
import { useSelector } from 'react-redux';
import { BlogSkeleton, NoItem } from '../../components';
import { useEffect, useState } from 'react';
import notFound from '../../assets/brp_world.png';
import {
	dateFormatter,
	doScrollToTop,
} from '../../helpers';
import { parseHtml } from '../helpers';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../themes';
import { SearchFormForBlog } from '../../components/search/SearchForm';
import { Helmet } from 'react-helmet';

export const Blog = () => {
	// todo: when navigating away and back from the blog
	// component, make sure that the filtering is reset so that
	// it shows all the posts again
	const [showAll, setShowAll] = useState<
		undefined | boolean
	>(undefined);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(
		theme.breakpoints.down('sm'),
	);
	const currentLocale = useSelector((state: RootState) =>
		state.locale.currentLanguage.toLowerCase(),
	);
	const prompts = useSelector(
		(state: RootState) => state.generic.languagePrompts,
	);
	const translations = prompts[currentLocale];
	const navigate = useNavigate();
	const isBeingFetched = useSelector(
		(state: RootState) => state.generic.blog.isBeingFetched,
	);
	const blogPosts = useSelector(
		(state: RootState) => state.generic.blog.posts,
	);
	const blogPostsBasedOnSearch = useSelector(
		(state: RootState) =>
			state.generic.blog.blogsBasedOnSearch,
	);
	const handleBlogSelection = (post) => {
		store.dispatch(updateSelectedPost(post));
		navigate(`/blog/${post.id}`);
	};

	let blogs = showAll
		? blogPosts
		: blogPostsBasedOnSearch ?? blogPosts;
	const generateBlogJsonLd = () => {
		return {
			'@context': 'https://schema.org',
			'@type': 'Blog',
			name: 'Jetpower Hungary Blog',
			url: 'https://jetpower.hu/blog',
			description:
				'Hírek és információk a BRP World termékekről - Can-Am, Sea-Doo, Ski-Doo és Lynx járművekről.',
			publisher: {
				'@type': 'Organization',
				name: 'Jetpower Hungary',
				logo: {
					'@type': 'ImageObject',
					url: 'https://jetpower.hu/apple-touch-icon.png',
				},
			},
			blogPost: blogs?.map((post) => ({
				'@type': 'BlogPosting',
				headline: post.title,
				image:
					post.main_image?.src ||
					'https://jetpower.hu/apple-touch-icon.png',
				datePublished: post.created_at,
				dateModified: post.updated_at,
				mainEntityOfPage: `https://jetpower.hu/blog/${post.id}`,
				author: {
					'@type': 'Organization',
					name: 'Jetpower Hungary',
				},
			})),
		};
	};
	const Posts = () => {
		if (!blogs || blogs.length < 1) {
			return <NoItem translations={translations} />;
		}
		const postNumber = blogs.length;
		const firstPostDetails = blogs[0];
		const firstPost = (
			<Grid
				item
				xs={12}
				md={postNumber > 2 ? 8 : 6}
				key={blogs[0].id}
				style={{
					marginBottom: '16px',
					border: isSmallScreen
						? 'none'
						: `1px solid ${colors['brp-grey'].faded}`,
					padding: isSmallScreen ? 0 : '32px',
					boxSizing: 'border-box',
				}}
			>
				<img
					src={firstPostDetails.main_image?.src ?? notFound}
					alt={firstPostDetails.title}
					style={{
						width: '100%',
						height: 'min(380px, 80%)',
						objectFit: 'contain',
						maxHeight: '300px',
					}}
				/>
				<Typography variant="body1" component="h4">
					{dateFormatter(firstPostDetails.updated_at)}
				</Typography>
				<Typography variant="h5" component="h3">
					{firstPostDetails.title}
				</Typography>
				<section
					className="blog description"
					style={{ marginTop: 1, marginBottom: 2 }}
				>
					{parseHtml(firstPostDetails.post.slice(0, 200))}
					...
				</section>
				<Button
					sx={{ marginTop: 2, width: 'max-content' }}
					variant="brp-white"
					size="small"
					onClick={() =>
						handleBlogSelection(firstPostDetails)
					}
				>
					{translations.shop.more}
				</Button>
			</Grid>
		);

		const otherPosts = blogs.slice(1, 4).map((post) => (
			<Grid
				className="nested"
				container
				key={post.id}
				style={{
					border: `1px solid ${colors['brp-grey'].faded}`,
					boxSizing: 'border-box',
					padding: '16px',
					marginBottom: '16px',
					flex: 1,
				}}
			>
				{post.title}
				<span
					style={{
						width: '100%',
						height: '2px',
						marginTop: '8px',
						marginBottom: '8px',
						backgroundColor: colors['brp-grey'].faded,
					}}
				></span>
				<section
					className="blog description"
					style={{ marginBottom: '16px' }}
				>
					{parseHtml(post.post.slice(0, 100))}...
				</section>
				<Button
					sx={{ marginTop: 'auto', width: 'max-content' }}
					variant="brp-white"
					size="small"
					onClick={() => handleBlogSelection(post)}
				>
					{translations.shop.more}
				</Button>
			</Grid>
		));
		const furtherPosts = blogs.slice(4).map((post) => (
			<Grid
				container
				key={post.id}
				style={{
					marginBottom: '16px',
					border: `1px solid ${colors['brp-grey'].faded}`,
					flexDirection: 'column',
					boxSizing: 'border-box',
					padding: '16px',
					height: 'calc(100% - 16px)',
				}}
			>
				<img
					src={firstPostDetails.main_image?.src ?? notFound}
					alt={firstPostDetails.title}
					style={{
						objectFit: 'contain',
					}}
				/>
				<Typography
					variant="body1"
					component="h4"
					sx={{
						width: 'max-content',
						position: 'relative',
					}}
				>
					<span
						style={{
							position: 'absolute',
							right: 0,
							left: 0,
							bottom: 0,
							height: '4px',
							backgroundColor: colors['brp-yellow'].main,
						}}
					></span>
					{dateFormatter(firstPostDetails.updated_at)}
				</Typography>
				<Typography variant="h6" component="h5">
					{post.title}
				</Typography>
				<section
					className="blog description"
					style={{ marginTop: '8px', marginBottom: '16px' }}
				>
					{parseHtml(post.post.slice(0, 100))}...
				</section>
				<Button
					sx={{ marginTop: 'auto', width: 'max-content' }}
					variant="brp-white"
					size="small"
					onClick={() => handleBlogSelection(post)}
				>
					{translations.shop.more}
				</Button>
			</Grid>
		));

		return (
			<>
				<Helmet>
					<meta
						name="description"
						content="Fedezze fel legfrissebb híreinket és információinkat a BRP World termékekről - Can-Am, Sea-Doo, Ski-Doo és Lynx járművekről."
					/>
					<meta
						name="keywords"
						content="BRP hírek, Can-Am újdonságok, Sea-Doo hírek, Ski-Doo információk, Lynx újdonságok, jetski hírek, quad hírek"
					/>
					<script type="application/ld+json">
						{JSON.stringify(generateBlogJsonLd())}
					</script>
				</Helmet>

				<Grid container columnGap={2} marginLeft={0}>
					{firstPost}
					<Grid
						id="this"
						item
						xs={12}
						md={4}
						alignItems="flex-start"
						flexDirection="column"
						sx={{ flex: '1 !important', display: 'flex' }}
					>
						{otherPosts.map((components) => components)}
					</Grid>
				</Grid>
				{isSmallScreen ? null : blogs.length > 4 ? (
					<Grid container marginTop={2} marginBottom={3}>
						<section
							style={{
								backgroundColor: colors['brp-yellow'].main,
								height: '80%',
								marginTop: 'auto',
								marginBottom: 'auto',
								width: '10px',
								marginLeft: '8px',
								marginRight: '8px',
								minHeight: '48px',
							}}
						></section>
						<Typography
							variant="h4"
							component="h3"
							sx={{ marginTop: '5px' }}
						>
							{translations.text.older_blogposts}
						</Typography>
					</Grid>
				) : null}
				<Grid columnSpacing={{ xs: 0, md: 2 }} container>
					{furtherPosts.map((components) => (
						<Grid
							item
							xs={12}
							md={4}
							alignItems="flex-start"
							flexDirection="row"
						>
							{components}
						</Grid>
					))}
				</Grid>
			</>
		);
	};
	const handleShowAll = () => {
		setShowAll(true);
		navigate('/blog');
		doScrollToTop();
	};
	useEffect(() => {
		store.dispatch(getBlogPosts());
	}, []);
	return isBeingFetched ? (
		<BlogSkeleton />
	) : (
		<Grid container maxWidth="lg" margin="0 auto">
			<Grid
				container
				sx={{
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					background: colors['brp-grey'].faded,
					padding: 4,
					marginBottom: 2,
				}}
			>
				<span
					onClick={handleShowAll}
					style={{
						cursor: 'pointer',
						marginRight: 'auto',
						visibility:
							showAll || showAll === undefined
								? 'hidden'
								: 'visible',
					}}
				>
					{translations.text.blog_show_all}
				</span>

				<Typography variant="h4" component="h3">
					{translations.text.blog_search_title}
				</Typography>
				<SearchFormForBlog
					dontShowAll={() => setShowAll(false)}
					style={{
						backgroundColor: colors['brp-white'].main,
						width: '300px',
						border: `1px solid ${colors['brp-grey'].main}`,
						borderRadius: 0,
					}}
				/>
			</Grid>
			<Posts />
		</Grid>
	);
};
// return (
//     <Grid container>
//         <iframe
//             src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjetpowerhungary%2F&tabs=timeline&width=500&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
//             width="500"
//             height="400"
//             style={{
//                 margin: '32px auto',
//                 border: 'none',
//                 overflow: 'hidden',
//             }}
//             allowFullScreen={true}
//             allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//         ></iframe>
//     </Grid>
// );
